<ion-content class="ion-padding ion-text-center">
  <div class="flex ion-justify-content-center container">
    <div class="d-flex flex-div ion-padding ion-margin-top">
      <img src="/assets/icon/app-update.svg" class="update-image" alt="">
    </div>
    
    <ng-container *ngIf="isUpdateAvailable; else elseTemplate">
      <h2 translate>newversion_available</h2>
      <p class="ion-margin-top ion-no-margin">{{'latest_version' | translate}}: <ion-text color="success">{{newVersion}}</ion-text></p>
      <p class="ion-no-margin">{{'your_version' | translate}}: <ion-text color="danger">{{currentVersion}}</ion-text></p>
      
      <br>
    
      <div class="ion-padding">
        <ng-container *ngIf="isAndroid; else appleStore">
          <ion-button (click)="immediateUpdate()" expand="block">
            <ion-icon slot="start" name="download-outline"></ion-icon>
            {{'update_appnow' | translate}}
          </ion-button>
        </ng-container>
        <ng-template #appleStore>
          <ion-button (click)="openAppStore()" expand="block">
            <ion-icon slot="start" name="logo-apple"></ion-icon>
            {{'open_on' | translate}} App Store
          </ion-button>
        </ng-template>
      </div>

    </ng-container>
    <ng-template #elseTemplate>
      <h2 translate>yourapp_uptodate</h2>
      
      <br>
      <br>
      
      <div class="ion-padding">
        <ion-button expand="block" (click)="closeModal()">
          {{'ok' | translate}}
        </ion-button>
      </div>
    </ng-template>
    
    <ion-footer class="ion-no-border footer-inside" *ngIf="isUpdateAvailable && isAndroid">
      <ion-toolbar class="ion-text-center ion-justify-content-center ion-no-padding">
        <ion-button (click)="openAppStore()" fill="clear" color="medium">
          <ion-icon slot="start" src="/assets/icon/playstore.svg"></ion-icon>
          {{'open_on' | translate}} Play Store
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </div>
</ion-content>
