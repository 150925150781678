import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { APIENDPOINT } from '../helpers/constant/api-end-points';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AccountHolderService {

  public userId: number = Number(localStorage.getItem("userId"));
  public reqBody: any;
  constructor(private baseService: BaseService, public toastController: ToastController) { }

  //Account holder registration
  public accountHolderRegistration(accountHolderData: any){
    accountHolderData.langauge = localStorage.getItem('currentLang') || 'en';
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.REGISTRATION.ACCOUNTHOLDERDATA, accountHolderData).pipe(catchError(this.errorHandler));
  }

  //Get church by id
  public getChurch(church_id: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.GETCHURCHBYID.CHURCHDETAILS + church_id).pipe(catchError(this.errorHandler));
  }

  //Get congregation by id
  public getCongregationByID(church_id: number, congregation: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.CONGREGATION.GET + church_id + '/' + congregation).pipe(catchError(this.errorHandler));
  }

  //Update church details
  public updateChurch(churchId: number, churchData: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.UPDATECHURCH.UPDATE + churchId +'/'+ this.userId, churchData).pipe(catchError(this.errorHandler));
  }

  //Get congregation
  public getCongregation(church_id: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.ACCOUNTHOLDERCONGREGATION.CONGREGATIONLIST + this.userId +'/'+ church_id).pipe(catchError(this.errorHandler));
  }

  //Add congregation
  public addCongregation(congregationData: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.CONGREGATION.ADD, congregationData).pipe(catchError(this.errorHandler));
  }

  //Update congregation
  public updateCongregation(congregationId: number, congregationData: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.CONGREGATION.UPDATE + congregationId +'/'+ this.userId, congregationData).pipe(catchError(this.errorHandler));
  }

  //Delete congregation
  public deleteCongregation(congregationId: number, congregationData: any){
    let congrData = {
      new_congregation_id: congregationData
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.CONGREGATION.DELETE + congregationId +'/'+ this.userId, congrData).pipe(catchError(this.errorHandler));
  }

  //Add funds
  public addFund(fundData: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDS.ADDFUND, fundData).pipe(catchError(this.errorHandler));
  }

  //Get funds
  public getFunds(church_id: number, offset: number = 0, title?:string){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDS.FUNDLIST + this.userId +'/'+ church_id + '/' + offset, title ? {title} : undefined).pipe(catchError(this.errorHandler));
  }

  //Update fund
  public updateFund(fund_id: number, churchId: any, fundData: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDS.UPDATEFUND + fund_id +'/'+ churchId + '/' + this.userId, fundData).pipe(catchError(this.errorHandler));
  }

  //Delete fund
  public deleteFund(fund_id: number){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDS.DELETEFUND + fund_id +'/'+ this.userId).pipe(catchError(this.errorHandler));
  }


  //Get fund raisers
  public getFundRaiser(church_id: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDRAISER.FUNDRAISERLIST + this.userId +'/'+ church_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //Create fund raiser
  public addFundRaiser(fundRaiserData: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDRAISER.ADDFUNDRAISER, fundRaiserData).pipe(catchError(this.errorHandler));
  }

  //Update fund raiser
  public updateFundRaiser(fund_raiser_id: number,  churchId: any, fundRaiserData: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDRAISER.UPDATEFUNDRAISER + fund_raiser_id +'/'+ churchId + '/' + this.userId, fundRaiserData).pipe(catchError(this.errorHandler));
  }

  //Delete fund
  public deleteFundRaiser(fundRaiserId: number){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDRAISER.DELETEFUNDRAISER + fundRaiserId +'/'+ this.userId).pipe(catchError(this.errorHandler));
  }

  //Get congregation list by church_id
  public getCongregationByChurchId(church_id: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.CONGREGATION.LIST + church_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //Get Statements
  public getStatements(reqInput: any){
    const dateVal = reqInput.month_year;
    let obj = new Date(dateVal);
    let monYearVal = obj.getMonth()+1 +'-'+ obj.getFullYear();
    this.reqBody = {
      church_id: reqInput.church_id,
      congregation_id: reqInput.congregation_id
    };
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.STATEMENT.LIST + monYearVal, this.reqBody).pipe(catchError(this.errorHandler));
  }

  //Export Statements on email
  public exportStatement(reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.STATEMENT.EXPORT, reqBody).pipe(catchError(this.errorHandler));
  }

  //Get Fund Tracker
  public getFundTrackerForChart(year: any, churchId: number, reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDTRACKER.FUNDLIST + year + '/' + churchId, reqBody).pipe(catchError(this.errorHandler));
  }

  //Get Fund Tracker Statements
  public getFundTrackerStatements(year: any, reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.FUNDTRACKER.STATEMENTS + year, reqBody).pipe(catchError(this.errorHandler));
  }

  //Export fund statements on email
  public exportFundStatement(reqBody: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.STATEMENT.FUNDEXPORT, reqBody).pipe(catchError(this.errorHandler));
  }

  //get shepherds
  public getShepherds(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.GETSHEPHERDS + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  // search shepherds by first name or last name
  public getShepherdsByName(name: string, churchId: any, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.GETSHEPHERDS + churchId + '/' + offset, {name}).pipe(catchError(this.errorHandler));
  }

  //connect paypal email
  public linkPaypal(churchId: any, email: string){
    let body = {
      church_id: churchId,
      paypal_email: email
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.ACCOUNTHOLDER.LINKPAYPAL, body).pipe(catchError(this.errorHandler));
  }
  
  public getSubscripitionPlans(offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.LIST + offset).pipe(catchError(this.errorHandler));
  }

  checkSubscriptionStatus() {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.INAPPPURCHASE.CHECKSTATUS).pipe(catchError(this.errorHandler));
  }
  
  public getSubscripitionPlansByCountry(country_id: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.BYCOUNTRY + country_id).pipe(catchError(this.errorHandler));
  }
  
  public getCurrentSubscripitionPlans(church: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.CURRENT + church + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  public getChurchBills(church: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.BILLS + church + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  public payChurchBill(church_id: number, bill_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SUBSCRIPTION.PAYBILL, {church_id, bill_id}).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }

  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['OK']
      });
    toast.present();
  }
}
