import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { APIENDPOINT } from '../helpers/constant/api-end-points';
import { ToastController } from '@ionic/angular';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ShepherdService {

  public userId: number = Number(localStorage.getItem("userId"));
  constructor(
    private baseService: BaseService,
    public toastController: ToastController
  ) { }

  //Get user permissions
  getUserEmpowermentPermissions(user: number){
    let church = Number(localStorage.getItem('userChurchId'));
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.PERMISSIONS + user + '/' + church).pipe(catchError(this.errorHandler));
  }

  //Get users by quick search
  getUsersByQuickSearch(name: {name: string, church_id: number}, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.QUICKSEARCH + offset, name).pipe(catchError(this.errorHandler));
  }

  //Get users by detailed search
  getUsersBySearch(name: any, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.DETAILEDSEARCH + offset, name).pipe(catchError(this.errorHandler));
  }

  //export users data
  exportUsers(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.EXPORTUSERS, req).pipe(catchError(this.errorHandler));
  }

  //get new links to church
  getNewLinks(church_id: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.NEWLINKS + church_id + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //Get sheep details by sheep and church id
  getSheepDetails(sheepId: number, churchId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.SHEEPDETAILS + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getEventTypes(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.GET.TYPES).pipe(catchError(this.errorHandler));
  }

  getSheepEventsActivity(sheepId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.EVENTS + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepCoursesActivity(sheepId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.COURSES + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepJourney(sheepId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.JOURNEY + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepNewsActivity(sheepId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.NEWS + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepGroupTeamActivity(sheepId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.GROUPTEAM + sheepId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getSheepAssignedSheeps(sheepId: number, churchId: number, body: any){
    body.sheep_id = sheepId;
    body.church_id = churchId;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.ASSIGNEDSHEEP, body).pipe(catchError(this.errorHandler));
  }

  getSheepGroupLeaders(sheepId: number, churchId: number, body: any){
    body.sheep_id = sheepId;
    body.church_id = churchId;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.GROUPLEADERS, body).pipe(catchError(this.errorHandler));
  }

  getSheepTeamLeaders(sheepId: any, churchId: any, body: any){
    body.sheep_id = sheepId;
    body.church_id = churchId;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ACTIVITY.TEAMLEADERS, body).pipe(catchError(this.errorHandler));
  }

  //Update congregation
  public updateCongregation(sheepId: number, shepherdId: number, reqBody: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.UPDATECONGREGATION + sheepId +'/'+ shepherdId, reqBody).pipe(catchError(this.errorHandler));
  }

  //Update shepherd
  public updateShepherd(sheepId: number, shepherdId: number, reqBody: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.UPDATESHEPHERD + sheepId +'/'+ shepherdId, reqBody).pipe(catchError(this.errorHandler));
  }

  //send message
  public sendMessage(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.COMMUNICATION, req).pipe(catchError(this.errorHandler));
  }

  //get all potential message receipients = all church members
  public getAllChurchMembers(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.GETALLCHURCHUSERS + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //get all potential message receipients = all church members
  public searchChurchMembers(name: string, churchId: any, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.GETALLCHURCHUSERS + churchId + '/' + offset, {name}).pipe(catchError(this.errorHandler));
  }

  //get class list
  getKidsList(church: number, kidsClass: number,offset: number = 0, name: string = ''){
    if (name.length) {
      return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.KIDSLIST + church + '/' + kidsClass + '/' + offset, {name}).pipe(catchError(this.errorHandler));
    }
      
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.KIDSLIST + church + '/' + kidsClass + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  assignKidToAClass(church: number, classId: number, body:any) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.ASSIGNKIDCLASS + church + '/' + classId, body).pipe(catchError(this.errorHandler));
  }
  
  dissociateKidToAClass(church: number, kid: number, classId: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.DISSOCIATEKID + church + '/' + kid + '/' + classId).pipe(catchError(this.errorHandler));
  }

  getClassList(churchId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CLASSLIST + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //get chart data by class id
  getChartData(classId: number, body: {start: string, end: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CHARTDATA + classId, body).pipe(catchError(this.errorHandler));
  }
  filterChartData(classId: number, body: {filter: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.FILTERDATA + classId, body).pipe(catchError(this.errorHandler));
  }

  //get class details by id
  getClassDetails(classId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CLASSDETAILS + classId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //Get users by quick search
  createClass(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CREATECLASS, name).pipe(catchError(this.errorHandler));
  }

  //add a kid visitor
  addVisitor(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.ADDVISITOR, name).pipe(catchError(this.errorHandler));
  }

  //add a kid visitor
  editVisitor(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.EDITVISITOR, name).pipe(catchError(this.errorHandler));
  }

  //open a class
  openClass(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.OPENCLASS, name).pipe(catchError(this.errorHandler));
  }

  //close a class
  closeClass(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CLOSECLASS, name).pipe(catchError(this.errorHandler));
  }

  //check in a kid
  checkIn(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CHECKIN, name).pipe(catchError(this.errorHandler));
  }

  //check out a kid
  checkOut(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.CHECKOUT, name).pipe(catchError(this.errorHandler));
  }

  //check out a kid
  sendCheckOutNotificationToKidParents(class_id: number, kid_id: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.SEND_CHECKOUT_NOTIFICATION, {class_id, kid_id}).pipe(catchError(this.errorHandler));
  }

  //Empower as shepherd {kids: true}
  empowerSheepAsShepherd(reqInput: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEPMANAGEMENT.EMPOWERMENT.SHEPHERD, reqInput).pipe(catchError(this.errorHandler));
  }
  //delete a class
  deleteClass(classId : number, userId: number){
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.KIDS.DELETECLASS + classId + '/' + userId).pipe(catchError(this.errorHandler));
  }

  //delink a sheep
  delinkSheep(name: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.DELINK, name).pipe(catchError(this.errorHandler));
  }

  //get list (filtered or unfiltered) of delinks data
  getDelinks(name: any, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.DELINKEDLIST + offset, name).pipe(catchError(this.errorHandler));
  }

  //get list (filtered or unfiltered) of delinks data
  getDelinkStats(church: number, time: string = 'all time'){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.DELINKEDSTATS + church + '/' + time).pipe(catchError(this.errorHandler));
  }


  //Empower as Administrator
  empowerSheepAsAdministrator(reqInput: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEPMANAGEMENT.EMPOWERMENT.ADMINISTRATOR, reqInput).pipe(catchError(this.errorHandler));
  }

  //Empower as Group leader
  empowerSheepAsGroupLeader(reqInput: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEPMANAGEMENT.EMPOWERMENT.GROUPLEADER, reqInput).pipe(catchError(this.errorHandler));
  }

  //Empower as Team Leader
  empowerSheepAsTeamLeader(reqInput: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEPMANAGEMENT.EMPOWERMENT.TEAMLEADER, reqInput).pipe(catchError(this.errorHandler));
  }

  //Empower as Team Leader
  removeRole(reqInput: {church_id: number, user_id: number, role_id: number}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEPMANAGEMENT.EMPOWERMENT.REMOVE, reqInput).pipe(catchError(this.errorHandler));
  }

  //create news item
  createNews(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.CREATENEWS, req).pipe(catchError(this.errorHandler));
  }

  //create poll item
  createPoll(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.CREATEPOLL, req).pipe(catchError(this.errorHandler));
  }

  //get pending sheep news
  getPendingNews(shepherdId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.PENDINGNEWS + shepherdId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //approve news submitted by sheep
  approveNews(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.APPROVENEWS, req).pipe(catchError(this.errorHandler));
  }

  //discard news submitted by sheep
  discardNews(req: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.DISCARDNEWS, req).pipe(catchError(this.errorHandler));
  }

  //list news items
  getNewsList(shepherdId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.LISTNEWS + shepherdId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //list poll items
  getPollList(shepherdId: any, churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.LISTPOLL + shepherdId + '/' + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  //delete news item
  deleteNews(newsId: any, shepherdId: any, churchId: any){
    let requestBody = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.DELETENEWS + newsId + '/' + shepherdId, requestBody).pipe(catchError(this.errorHandler));
  }

  //delete poll item
  deletePoll(pollId: any, shepherdId: any, churchId: any){
    let requestBody = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.DELETEPOLL + pollId + '/' + shepherdId, requestBody).pipe(catchError(this.errorHandler));
  }

  //get news by id
  getNewsById(newsId: any, churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.GETNEWSBYID + newsId + '/' + churchId).pipe(catchError(this.errorHandler));
  }

  //get poll by id
  getPollById(newsId: any, churchId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.GETPOLLBYID + newsId + '/' + churchId).pipe(catchError(this.errorHandler));
  }

  //delete news comment
  deleteNewsComment(newsId: any, commentId: any, shepherdId:any, churchId: any){
    let requestBody = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.DELETENEWSCOMMENT + newsId + '/' + commentId + '/' + shepherdId, requestBody).pipe(catchError(this.errorHandler));
  }

  //delete poll comment
  deletePollComment(newsId: any, commentId: any, shepherdId:any, churchId: any){
    let requestBody = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.DELETEPOLLCOMMENT + newsId + '/' + commentId + '/' + shepherdId, requestBody).pipe(catchError(this.errorHandler));
  }

  //update news
  updateNews(newsId: any, userId: any, req: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.UPDATENEWS + newsId + '/' + userId, req).pipe(catchError(this.errorHandler));
  }

  //update poll
  updatePoll(newsId: any, userId: any, req: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.NEWS.UPDATEPOLL + newsId + '/' + userId, req).pipe(catchError(this.errorHandler));
  }

  // get Notes for sheepid
  getSheepNotes(sheepId: number, offset: number = 0) {
    let church = Number(localStorage.getItem('userChurchId'));
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.NOTES.LIST + sheepId + '/' + church + '/' + offset).pipe(catchError(this.errorHandler));
  }

  // get Notes for sheepid
  addSheepNotes(sheepId: number, note: string) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.NOTES.ADD + sheepId, {note}).pipe(catchError(this.errorHandler));
  }

  // delete Notes for sheepid
  deleteSheepNotes(noteId: number, shepherdId: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.NOTES.DELETE + noteId +'/'+ shepherdId).pipe(catchError(this.errorHandler));
  }

  // STATISTICS
  //get active sheep
  public getActiveSheep(churchId: number, filter: string, congregationId: any = null ){
    let body = {
      congregation: congregationId,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETACTIVESHEEP + churchId, body).pipe(catchError(this.errorHandler));
  }

  //get active courses stats
  public getActiveCourses(churchId: number, offset: number = 0, congregationId: any = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETACTIVECOURSE + churchId + '/' + offset + (congregationId ? '/' + congregationId : '')).pipe(catchError(this.errorHandler));
  }

  //get event report stats
  public getEventReports(churchId: number, event: number|null = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETEVENTREPORT + churchId + (event ? '/' + event : '')).pipe(catchError(this.errorHandler));
  }

  //get event report stats
  public getEventReportsExtendedStats(churchId: number, type: string = 'all', congregation: number|string = 'all', time: string = 'all time'){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETEVENTREPORTSTATS + churchId + '/' + type + '/' + congregation, {time}).pipe(catchError(this.errorHandler));
  }

  //get active team leaders
  public getActiveTeamLeaders(churchId: number, filter: string, congregationId: any = null ){
    let body = {
      congregation: congregationId,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETTEAMLEADERS + churchId, body).pipe(catchError(this.errorHandler));
  }

  //get active group leaders
  public getActiveGroupLeaders(churchId: number, filter: string, congregationId: any = null ){
    let body = {
      congregation: congregationId,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETGROUPLEADERS + churchId, body).pipe(catchError(this.errorHandler));
  }

  //get active shepherds
  public getActiveShepherds(churchId: number, filter: string, shepherd: any ){
    let body = {
      shepherd: shepherd,
      filter_by: filter
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.STATISTICS.GETACTIVESHEPHERD + churchId, body).pipe(catchError(this.errorHandler));
  }


  //events

  //create events
  public createEvents(body: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.CREATE, body).pipe(catchError(this.errorHandler));
  }

  public getAllEvents(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.GET.ALL + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  public getUpcomingEvents(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.GET.UPCOMING + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  public getPastEvents(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.GET.PAST + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }

  public getEventDetails(churchId: any, eventId:any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.GET.DETAILS + churchId + '/' + eventId).pipe(catchError(this.errorHandler));
  }

  public updateEvent(churchId: any, eventId:any, body: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.UPDATE + churchId + '/' + eventId, body).pipe(catchError(this.errorHandler));
  }

  public deleteEvent(eventId: any, churchId: any){
    let body = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.DELETE + eventId, body).pipe(catchError(this.errorHandler));
  }

  public updateEventReport(body: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.REPORT.UPDATE, body).pipe(catchError(this.errorHandler));
  }

  public getEventAttendees(churchId: any, eventId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ATTENDEES.GET + churchId + '/' + eventId  + '/' + offset).pipe(catchError(this.errorHandler));
  }

  public eventDeregistration(churchId: number, eventId: number, userId: number){
    let body = {
      church_id: churchId,
      event_id: eventId,
      user_id: userId,
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.DEREGISTER, body).pipe(catchError(this.errorHandler));
  }

  public exportEventAttendees(churchId: any, eventId: any, email: string){
    let body = {
      church_id: churchId,
      event_id: eventId,
      email: email
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ATTENDEES.EXPORT, body).pipe(catchError(this.errorHandler));
  }

  public saveRoster(body: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.SAVE, body).pipe(catchError(this.errorHandler));
  }

  public listRoster(churchId: any, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.LIST + churchId + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  public searchRoster(churchId: number, offset: number = 0, name?: string){
    if (name) {
      return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.SEARCH + churchId + '/' + offset, {name}).pipe(catchError(this.errorHandler));
    } else {
      return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.SEARCH + churchId + '/' + offset).pipe(catchError(this.errorHandler));
    }
  }

  public rosterDetails(churchId: any, rosterId: any){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.DETAILS + churchId + '/' + rosterId).pipe(catchError(this.errorHandler));
  }

  public rosterParticipants(body:any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.PARTICIPANTS, body).pipe(catchError(this.errorHandler));
  }

  public updateRoster(body: any){
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.UPDATE, body).pipe(catchError(this.errorHandler));
  }

  public deleteRoster(rosterId: any, churchId: any){
    let body = {
      church_id: churchId
    }
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.ROSTERS.DELETE + rosterId, body).pipe(catchError(this.errorHandler));
  }

  public verifyTicket(church_id: number, ticket_serial_no: string) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.TICEKTS.CHECKVALIDITY + church_id + '/' + ticket_serial_no).pipe(catchError(this.errorHandler));
  }

  public markTicketAsUsed(church_id: number, ticket_serial_no: string) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.EVENTS.TICEKTS.MARKAS_USED, {church_id, ticket_serial_no}).pipe(catchError(this.errorHandler));
  }

  // Courses
  addNewCourse(church: number, body: any) {
    body.church_id = church;
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.CREATE, body).pipe(catchError(this.errorHandler));
  }

  installCourse(church_id: number, course_id: number, congregations: number[]|string[] = ['all']) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.INSTALL, {church_id, course_id, congregations}).pipe(catchError(this.errorHandler));
  }

  uninstallCourse(church_id: number, course_id: number) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.UNINSTALL, {church_id, course_id}).pipe(catchError(this.errorHandler));
  }

  getCourse(church_id: number, course_id: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.GETDETAIL + church_id + '/' + course_id).pipe(catchError(this.errorHandler));
  }

  updateCourse(church_id: number, course_id: number, body: {church_id?: number, title: string, description: string, video_url?: string, photo?: string, installable: boolean }) {
    body.church_id = church_id;
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.UPDATE + course_id, body).pipe(catchError(this.errorHandler));
  }

  updateCourseSession(church_id: number, course_id: number, session_id: number, body: any) {
    body.church_id = church_id;
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.UPDATESESSION + course_id + '/' + session_id, body).pipe(catchError(this.errorHandler));
  }

  removeTestimony(church: number, course: number, testimony: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.TESTIMONY.REMOVE + church + '/' + course + '/' + testimony).pipe(catchError(this.errorHandler));
  }

  getTestimonies(course: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.TESTIMONY.LIST + course + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getInstoreCoursesList(church: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.LIST.INSTORE + church + '/' + offset).pipe(catchError(this.errorHandler));
  }

  getCourseLiveUpdate(church_id: number, course_id: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.LIST.LIVEUPDATE + church_id + '/' + course_id).pipe(catchError(this.errorHandler));
  }

  getManageCoursesList(church: number, offset: number = 0, title?: string) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.LIST.MANAGE + church + '/' + offset, title ? {title} : undefined).pipe(catchError(this.errorHandler));
  }

  removeCourse(church: number, course: number = 0) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.COURSE.REMOVE + church + '/' + course).pipe(catchError(this.errorHandler));
  }

  addSheepIntoGroup(groupId: number, sheepId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ADDTOGROUP + groupId + '/' + sheepId).pipe(catchError(this.errorHandler));
  }

  addSheepIntoTeam(teamId: number, sheepId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.ADDTOTEAM + teamId + '/' + sheepId).pipe(catchError(this.errorHandler));
  }

  // Tags
  getListOfUsersForTag(church: number, tag: number, offset: number = 0, name: string = '') {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.LISTUSERS + church + '/' + tag + '/' + offset + (name.length ? '/' + name : '')).pipe(catchError(this.errorHandler));
  }

  assignChurchTagToUsers(church: number, tag: number, sheeps: {user_id: number, action: string}[]) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.ASSIGNTOUSERS + church + '/' + tag, {sheeps}).pipe(catchError(this.errorHandler));
  }

  assignChurchTag(church: number, sheep: number, tags: number[]) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.ASSIGN + church + '/' + sheep, {tags}).pipe(catchError(this.errorHandler));
  }

  addChurchTag(church: number, body: {name: string, color: string, rule: string, model: string, model_value: string|number}) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.ADD + church, body).pipe(catchError(this.errorHandler));
  }

  editChurchTag(church: number, churchTag: number, body: {name: string, color: string, rule: string, model: string, model_value: string|number}) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.EDIT + church + '/' + churchTag, body).pipe(catchError(this.errorHandler));
  }

  deleteChurchTag(church: number, churchTag: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.DELETE + church + '/' + churchTag).pipe(catchError(this.errorHandler));
  }

  getChurchTags(church: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.LIST + church).pipe(catchError(this.errorHandler));
  }

  revokeChurchTag(church: number, sheep: number, churchTag: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SHEEPMANAGEMENT.TAGS.REVOKE + church + '/' + sheep + '/' + churchTag).pipe(catchError(this.errorHandler));
  }

  // SERMONS and PRAUYER REQUEST
  getSermonsList(church: number = Number(localStorage.getItem('userChurchId')), offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.SERMONS.LIST + church + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  createSermon(body: {
    church_id: number,
    series_type: 'existing'|'new',
    series_name?: string,
    series_id?: number,
    title: string,
    preacher_type: 'guest'|'shepherd',
    preacher_name?: string,
    preacher_image?: string,
    shepherd_id?: number,
    attachment: string,
    max_page: number,
    discussions: string[]
  }) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.SERMONS.SAVE, body).pipe(catchError(this.errorHandler));
  }
  
  updateSermon(sermon: number, body: {
    church_id: number,
    title: string,
    preacher_type: 'guest'|'shepherd',
    preacher_name?: string,
    preacher_image?: string,
    shepherd_id?: number,
    attachment: string,
    max_page: number,
    discussions: string[]
  }) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SERMONS.UPDATE + sermon, body).pipe(catchError(this.errorHandler));
  }
  
  publishSermonNow(sermon: number, publish_date: string|Date = format(new Date, 'yyyy-MM-dd HH:mm:ss')) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.SERMONS.PUBLISH + sermon, {publish_date}).pipe(catchError(this.errorHandler));
  }
  
  unpublishSermonNow(sermon: number) {
    let church = Number(localStorage.getItem('userChurchId'));
    return this.archiveSermon(church, sermon);
  }
  
  archiveSermon(church: number, sermon: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.SERMONS.ARCHIVE + church + '/' + sermon).pipe(catchError(this.errorHandler));
  }
  
  getPrayersRequests(church: number, offset: number = 0) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.LIST + church + '/' + offset).pipe(catchError(this.errorHandler));
  }
  
  getPrayerRequestDetail(church: number, prayer: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.DETAIL + church + '/' + prayer).pipe(catchError(this.errorHandler));
  }
  
  removePrayerRequestComment(church: number, prayer: number, comment: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.DETAIL + church + '/' + prayer + '/' + comment).pipe(catchError(this.errorHandler));
  }
  
  createPrayerRequest(body: {church_id: number, title: string, media: string, items: string[]}) {
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.CREATE, body).pipe(catchError(this.errorHandler));
  }
  
  updatePrayerRequest(prayer: number, body: {church_id: number, title: string, media: string, items: {id: number, item: string}[]}) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.UPDATE + prayer, body).pipe(catchError(this.errorHandler));
  }
  
  deletePrayerRequest(church: number, prayer: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.SHEPHERD.PRAYER_REQUEST.DELETE + church + '/' + prayer).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }

  //Success toast
  async successToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'success',
        buttons: ['Ok']
      });
    toast.present();
  }

  //Error toast
  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['Ok']
      });
    toast.present();
  }
}
