import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AppUpdateService {

  constructor(
    private toastController: ToastController
  ) { }

  isUpdateAvailable = async () => {
    try {
      const result = await AppUpdate.getAppUpdateInfo();
      return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
    } catch (error) {
      console.log('App Update error', {error})
      return false;
    }
  };

  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.currentVersionName;
  };

  getAvailableAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    return result.availableVersionName;
  };

  openAppStore = async () => {
    await AppUpdate.openAppStore();
  };

  performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();

    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') {
      AppUpdate.openAppStore();
    }

    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };

  startFlexibleUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.flexibleUpdateAllowed) {
      await AppUpdate.startFlexibleUpdate();
    }
  };

  completeFlexibleUpdate = async () => {
    await AppUpdate.completeFlexibleUpdate();
  };

  async showMessage(message: string) {
    const toast = await this.toastController.create({
      message,
      buttons: ['Ok'],
      color: 'warning'
    });
    await toast.present();
  }

}