import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollCustomEvent, IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule, AvatarSource } from 'ngx-avatars';
import { AccountHolderService } from 'src/app/services/account-holder.service';

@Component({
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, AvatarModule, TranslateModule],
  selector: 'app-shepherd-searchable-select',
  templateUrl: './shepherd-searchable-select.component.html',
  styleUrls: ['./shepherd-searchable-select.component.scss'],
})
export class ShepherdSearchableSelectComponent implements OnChanges {
  @Input() title = 'Search';
  @Input() data: any[] = [];
  @Input() selected: any[] = [];
  @Input() multiple = false;
  @Input() itemTextField : string;
  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();

  fetching: boolean = false;
  isOpen = false;
  isSearching = false;
  searching = false;
  filtered: any[] = [];
  filterData: string|undefined;
  
  church = localStorage.getItem('userChurchId');

  constructor(
    public accountHolderService: AccountHolderService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.filtered = this.data;
    this.selected = this.selected || [];
  }

  loadData(event: any) {
    if(this.isSearching == true && this.filterData){
      this.searching = true;
      this.accountHolderService.getShepherdsByName(this.filterData, this.church, this.filtered.length).subscribe((respData: any) => {
        this.searching = false;
        if(respData.shepherds) {
          this.filtered.push(...respData.shepherds);
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      });
    }else{
      this.accountHolderService.getShepherds(this.church, this.data.length).subscribe((respData: any) => {
        if(respData.shepherds) {
          this.data.push(...respData.shepherds);
        }
        (event as InfiniteScrollCustomEvent).target.complete();
      });
    }
  }

  open(){
    this.isOpen = true;
    if (this.data.length == 0) {
      this.fetching = true;
      this.accountHolderService.getShepherds(this.church, this.data.length).subscribe((res: any) => {
        if(res.shepherds) {
          this.data = res.shepherds;
        } else {
          this.accountHolderService.errorToast(res.message);
        }
        this.fetching = false;
      });
    }
  }

  cancel(){
    this.isOpen = false;
    this.data = [];
  }

  select(){
    const selected = this.data.filter((item) => item.selected);
    this.selected = selected;
    this.selectedChanged.emit(selected);
    this.isOpen = false;
  }
  
  selectedItem(item: any){
    this.selected = [item];

    if(!this.multiple){
      if(this.selected.length){
        this.selected[0].selected = false;
      }
      this.selectedChanged.emit(this.selected);
      this.isOpen = false;
      this.filtered = this.data;
    }
  }

  filter(event: any) {
    this.isSearching = true;
    this.filterData = event.detail.value?.toLowerCase();
    if(this.filterData){
      this.isSearching = true;
      if(this.filterData.length >= 3) {
        this.searching = true;
        this.accountHolderService.getShepherdsByName(this.filterData, this.church).subscribe((respData: any) => {
          this.searching = false;
          if(respData.shepherds) {
            this.filtered = respData.shepherds;
          }else{
            this.filtered = [];
          }
        });
      }
    }else{
      this.isSearching = false;
      this.accountHolderService.getShepherds(this.church).subscribe((respData: any) => {
        this.searching = false;
        if(respData.shepherds) {
          this.data = respData.shepherds;
        }
      });
    }
  }

  leaf = (obj: any) => this.itemTextField.split('.').reduce((value, el) => value[el], obj);

}
