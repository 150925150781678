import { FormGroup } from "@angular/forms";
import { Capacitor } from "@capacitor/core";

const isWeb = () => Capacitor.getPlatform() === 'web';
const isAndroid = () => Capacitor.getPlatform() === 'android';
const isIos = () => Capacitor.getPlatform() === 'ios';
const isNative = () => Capacitor.isNativePlatform();

const normalizeUrl = (url: string) => {
    let slug = '/';
    if (url.startsWith('http')) {
        // like https://supasheep-staging.globaledge-software.com/#/area/sheep/news
        slug = url.split("/#").pop() as string;
    } else if (url.startsWith('supa') || url.startsWith('avance')) {
        // like supasheep://area/sheep/news // backward compatibility
        // like avance://area/sheep/news
        let glue = url.startsWith('supa') ? 'supasheep:/' : 'avance:/';
        slug = url.split(glue).pop() as string;
    }
    return slug;
}

let orderNumber = 0;

export const generateUniqueInt = (length: number = 10) => {
    orderNumber = (orderNumber + 1) % 10; // Increment and reset after 9
    const timePart = Date.now().toString().slice(-length); // Take the last 6 digits
    return parseInt(timePart + orderNumber);
};

export const toNumber = (v: string|number|undefined|boolean) => {
    v = v === true || v && /true/ig.test(v + '') ? 1 : (v === false || v && /true/ig.test(v + '') ? 0 : v);
    return parseFloat('0' + v);
}

const passwordMatchValidator = (controlName: string, matchingControlName: string) => {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (
            matchingControl.errors &&
            !matchingControl.errors['password_match']
        ) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ password_match: true });
        } else {
            matchingControl.setErrors(null);
        }
    };
}

const shuffle = (str: string | string[]) => [...str].sort(() => Math.random() - .5).join('');

const randomString = () => [...'abcdefghijklmnopqrstuvwxyz'].sort(() => Math.random() - .5).join('');

const urlLinksRegex = /((http|ftp)(s)?:\/\/)?([(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6})\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/ig;

const youtubeVideoRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|live\/|shorts\/|v\/)?)([\w\-]{11})(\S+)?$/i; // id video is group 6

const vimeoVideoRegex = /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/i; // id video is group 1

const dmotionVideoRegex = /^(?:http|https)?:?\/?\/?(?:www\.)?dailymotion\.com\/(?:embed\/)?video\/(.*?)$/i; // id video is group 1

const base64toFile = (dataUrl: string, filename: string | null = null) => {
    let arr = dataUrl.split(','),
        mime = dataUrl.match(/:(.*?);/),
        bstr = window.atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    if (mime) {
        let ext = dataUrl.match(/\/(.*?);/) as string[];
        filename = filename || `${randomString()}.${ext[1]}`;
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime[1] });
    }
    return false
}

const blobToBase64 = (blob: Blob | File): Promise<string | null | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.onerror = (ev) => {
            reject('An error has occured!');
        }
        reader.readAsDataURL(blob);
    });
}

const waiter = (duration: number = 2000) => {
    return new Promise((resolve) => {
        let t = setTimeout(
            () => {
                clearTimeout(t)
                resolve(true);
            },
            duration
        )
    })
}

const blobToArrayBuffer = (blob: Blob | File): Promise<string | null | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            resolve(reader.result);
        }
        reader.onerror = (ev) => {
            reject('An error has occured!');
        }
        reader.readAsArrayBuffer(blob);
    });
}

const base64ToArrayBuffer = (base64: string): Promise<ArrayBufferLike> => {
    return new Promise((resolve, reject) => {
        let binaryString = window.atob(base64);
        let bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        resolve(bytes.buffer);
    });
}

const getVideoProvider = (videoUrl: string): false | Plyr.Provider => {
    let isYoutube = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))/i.test(videoUrl);
    let isVimeo = /^(?:http|https)?:?\/?\/?(?:player.|www.)?vimeo\.com/i.test(videoUrl);
    let isDmotion = /^(?:http|https)?:?\/?\/?(?:www\.)?dailymotion\.com/i.test(videoUrl);
    let isSupportedHtml5Video = videoUrl.toLowerCase().endsWith('mp4') || videoUrl.toLowerCase().endsWith('webm');
    return isYoutube ? 'youtube' : isVimeo ? 'vimeo' : isSupportedHtml5Video ? 'html5' : false; // support youtube and vimeo for now
    // return isYoutube ? 'youtube' : isVimeo ? 'vimeo' : isDmotion ? 'dailymotion' : false;
}

const isValidVideoUrl = (videoUrl: string) => {
    let provider = getVideoProvider(videoUrl);
    if (!provider) {
        return false;
    }
    switch (provider) {
        case 'youtube':
            return youtubeVideoRegex.test(videoUrl);
        case 'vimeo':
            return vimeoVideoRegex.test(videoUrl);
        case 'html5':
            return true;
        // case 'dailymotion':
        //     return dmotionVideoRegex.test(videoUrl);
        default:
            return false;
    }
}

const getVideoId = (videoUrl: string) => {
    let provider = getVideoProvider(videoUrl);
    if (!provider) {
        return null;
    }
    let regexMatches = [];
    switch (provider) {
        case 'youtube':
            regexMatches = youtubeVideoRegex.exec(videoUrl) as RegExpMatchArray;
            return regexMatches[6] ?? null;
        case 'vimeo':
            regexMatches = vimeoVideoRegex.exec(videoUrl) as RegExpMatchArray;
            return regexMatches[1] ?? null;
        // case 'dailymotion':
        //     regexMatches = dmotionVideoRegex.exec(videoUrl) as RegExpMatchArray;
        //     return regexMatches[1] ?? null;
        default:
            return null;
    }
}

const textHasLinks = (text: string) => {
    return urlLinksRegex.test(text);
}

const replaceUrlsByHtmlLinks = (text: string) => {
    if (textHasLinks(text)) {
        return text.replace(/((http|ftp)(s)?:\/\/)?([(www\.)?a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{2,6})\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig, (matchedText) => {
            if (!/((http|ftp)(s)?:\/\/)/ig.test(matchedText)) {
                return `<a href="http://${matchedText}" target="_blank">${matchedText}</a>`;
            }
            return `<a href="${matchedText}" target="_blank">${matchedText}</a>`;
        });
    }
    return text;
}

const getVideoProviderAndId = (videoUrl: string) => {
    if (isValidVideoUrl(videoUrl)) {
        return { match: true, provider: getVideoProvider(videoUrl), videoId: getVideoId(videoUrl) };
    }
    return { match: false, provider: false, videoId: null };
}

const stringToColor: (str: string) => string = (str: string) => {
    let hash = 0;
    str.split('').forEach(char => {
        hash = char.charCodeAt(0) + ((hash << 10) - hash);
    });
    let color = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 6)) & 0xdd;
        color += value.toString(16).padStart(2, '9');
    }
    return color;
}

const stripHtmlTagsKeepNewlines = (input: string): string => {
    // Replace <br> tags with line breaks (\n)
    input = input.replace(/<br\s*\/?>/gi, '\n');

    // Remove all other HTML tags
    return input.replace(/<[^>]*>/g, '');
}

export { stripHtmlTagsKeepNewlines, isAndroid, isIos, isNative, isWeb, normalizeUrl, base64toFile, randomString, shuffle, youtubeVideoRegex, vimeoVideoRegex, dmotionVideoRegex, getVideoProviderAndId, getVideoProvider, getVideoId, isValidVideoUrl, blobToBase64, blobToArrayBuffer, base64ToArrayBuffer, emailRegex, urlLinksRegex, textHasLinks, replaceUrlsByHtmlLinks, waiter, passwordMatchValidator, stringToColor };