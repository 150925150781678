import { Injectable } from '@angular/core';
import { catchError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BaseService } from './base.service';
import { APIENDPOINT } from '../helpers/constant/api-end-points';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {
  country: any;
  city: any;
  church: any;
  constructor(private baseService: BaseService, public toastController: ToastController) { }

  public getCountries(offset: number = 0, hasSubscriptions: boolean = false){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.COUNTRIES.COUNTRIESLIST + offset + (hasSubscriptions ? '/1' : '')).pipe(catchError(this.errorHandler));
  }

  public shareFundQRCode(data: {type: 'fund'|'fundraiser', email: string, link: string, qrcode: string}, church: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.SHAREQRCODE + church, data).pipe(catchError(this.errorHandler));
  }

  public getFundFundraiser(fund: number, type: 'fund'|'fundraiser' = 'fund'){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.GET + type + '/' + fund).pipe(catchError(this.errorHandler));
  }

  //make donations to fund or fundraiser
  makeGuestDonation(body: {fund: number, user?: string|null, type: 'fund'|'fundraiser', amount: number}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.GUESTDONATION.MAKEPAYMENT, body).pipe(catchError(this.errorHandler));
  }

  checkReferralCode(code: string) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.CHECK_REFERRALCODE + code).pipe(catchError(this.errorHandler));
  }

  //send order checks for payments
  checkGuestOrder(order_id: string, captured_order_id: string, user?: string|null, payment_capture_id?: string){
    let requestBody = {
      order_id,
      captured_order_id,
      user,
      payment_capture_id
    }

    return this.baseService.post(environment.baseUrl + APIENDPOINT.SHEEP.GIVING.GUESTDONATION.CAPTUREORDER, requestBody).pipe(catchError(this.errorHandler));
  }

  public inviteMembers(data: {email: string, link: string, qrcode: string}, church: number){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.CHURCHES.INVITEMEMBERS + church, data).pipe(catchError(this.errorHandler));
  }

  public getChurchById(church: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.CHURCHES.GET + church).pipe(catchError(this.errorHandler));
  }

  public getCountry(id: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.COUNTRIES.GETCOUNTRY + id).pipe(catchError(this.errorHandler));
  }

  public getCountryByTimezone(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.GETCOUNTRYBYTIMEZONE).pipe(catchError(this.errorHandler));
  }

  public searchCountries(name: string, offset: number = 0, hasSubscriptions: boolean = false){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.COUNTRIES.COUNTRIESSEARCH + offset + (hasSubscriptions ? '/1' : ''), {name}).pipe(catchError(this.errorHandler));
  }

  public getRates(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.RATES);
  }

  public getCities(country_id: number){
    this.country = {
      country_id: country_id
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.CITIES.CITIESLIST, this.country).pipe(catchError(this.errorHandler));
  }
  public searchCities(name: string, offset: number = 0, country: any = undefined){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.CITIES.CITIESSEARCH + offset + (country ? '/' + country : ''), {name}).pipe(catchError(this.errorHandler));
  }
  public getChurches(city_id: number, offset: number = 0){
    this.city = {
      city_id: city_id
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.CHURCHES.CHURCHLIST + offset, this.city).pipe(catchError(this.errorHandler));
  }

  public getCongregation(church_id: number){
    this.church = {
      church_id: church_id
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.CONGREGATION.CONGREGATIONLIST, this.church).pipe(catchError(this.errorHandler));
  }

  public getRelationShip(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.RELATIONSHIP.RELATIONSHIPLIST).pipe(catchError(this.errorHandler));
  }

  public getGender(){

    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.GENDER.GENDERLIST).pipe(catchError(this.errorHandler));
  }

  /**
   * 
   * @param church 
   * @param offset 
   * @param church2 
   * @param inclusive if the request should include the current user in the query
   */
  public getFamilyMembers(church: number, offset = 0, church2?: any, inclusive?: boolean){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.FAMILYMEMBERS.FAMILYMEMBERLIST + church + '/' + offset + ((church2 &&= '/' + church2) || ''), inclusive ? {inclusive} : undefined).pipe(catchError(this.errorHandler));
  }

  public getSkills(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.EXPERIENCES).pipe(catchError(this.errorHandler));
  }

  /**
   * 
   * @param name name to filter
   * @param church 
   * @param offset 
   * @param church2 
   * @param inclusive if the request should include the current user in the query
   */
  public searchFamilyMembers(name:string, church: number, offset = 0, church2?: any, inclusive?: boolean){
    let reqBody = {
      name: name
    }
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.FAMILYMEMBERS.FAMILYMEMBERLIST + church + '/' + offset + ((church2 &&= '/' + church2) || ''), {name}, inclusive ? {inclusive} : undefined).pipe(catchError(this.errorHandler));
  }

  public getRoles(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.GETROLES.ROLES).pipe(catchError(this.errorHandler));
  }

  public getCurrencies(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.CURRENCIES.CURRENCIESLIST).pipe(catchError(this.errorHandler));
  }

  public getFrequency(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.FREQUENCY.FREQUENCYLIST).pipe(catchError(this.errorHandler));
  }

  public getNotificationCount() {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.COUNT).pipe(catchError(this.errorHandler));
  }

  public getAppNotificationsList(offset: number = 0, filter: 'all'|'read'|'unread' = 'all') {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.LIST + offset + '/' + filter).pipe(catchError(this.errorHandler));
  }

  public markAllNotificationsAsRead() {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.READALL).pipe(catchError(this.errorHandler));
  }

  public markSelectedNotificationsAsRead(notifications: number[]) {
    return this.baseService.put(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.READSELECTED, { notifications }).pipe(catchError(this.errorHandler));
  }

  public removeSelectedNotifications(notifications: number[]) {
    return this.baseService.deleteWithBody(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.REMOVE, { notifications }).pipe(catchError(this.errorHandler));
  }

  public readNotification(id: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.APP_NOTIFICATION.READ + id).pipe(catchError(this.errorHandler));
  }

  // Register firebase cloud messaging token to the database
  public registerFCMToken(userId: number, token: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.PUSH_NOTIFICATION.REGISTER_TOKEN + userId, {token}).pipe(catchError(this.errorHandler));
  }

  // Register firebase cloud messaging token to the database
  public getUserRoleForChurch(userId: number, churchId: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.AUTH.ROLES + userId + '/' + churchId).pipe(catchError(this.errorHandler));
  }

  public getSupportDetails(support: number){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUPPORT.DETAILS + support).pipe(catchError(this.errorHandler));
  }

  public getSupportList(offset: number = 0, userId: number|null = null){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUPPORT.LIST + offset + '/' + userId ).pipe(catchError(this.errorHandler));
  }

  public createSupport(userId: number, req: {title: string, description: string}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SUPPORT.CREATE + userId, req ).pipe(catchError(this.errorHandler));
  }

  public getAllMessages(supportId: number, offset: number = 0){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUPPORT.LOAD_MESSAGES + supportId + '/' + offset ).pipe(catchError(this.errorHandler));
  }

  public sendMessage(userId: number, supportId: number, message: {message: string, is_admin?: boolean}){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SUPPORT.SEND_MESSAGE + userId + '/' + supportId, message ).pipe(catchError(this.errorHandler));
  }

  public receiptCoachingMessage(userType: 'coach'|'student', messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.MESSAGE.RECEIPTMESSAGE(userType, messageId)).pipe(catchError(this.errorHandler));
  }

  public readCoachingMessage(userType: 'coach'|'student', messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SHEEP.COURSE.MESSAGE.READMESSAGE(userType, messageId)).pipe(catchError(this.errorHandler));
  }

  public receiptMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUPPORT.RECEIPTMESSAGE(messageId)).pipe(catchError(this.errorHandler));
  }

  public readMessage(messageId: number) {
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SUPPORT.READMESSAGE(messageId)).pipe(catchError(this.errorHandler));
  }

  public markAs(supportId: number, status: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SUPPORT.MARK_AS + supportId, {status} ).pipe(catchError(this.errorHandler));
  }

  // for quick search
  public findChurchByName(name: string, offset: number = 0){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.UTILS.CHURCH.FIND + offset, {name} ).pipe(catchError(this.errorHandler));
  }

  // for quick search
  public checkEmail(email: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.UTILS.EMAILVERIFICATION.CHECK, {email} ).pipe(catchError(this.errorHandler));
  }

  // for quick search
  public sendVerificationLink(email: string){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.UTILS.EMAILVERIFICATION.RESEND, {email} ).pipe(catchError(this.errorHandler));
  }

  public removeNewsMedia(id: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.MEDIAS.NEWS.DELETE + id).pipe(catchError(this.errorHandler));
  }
  
  public removeFundsMedia(id: number, type: string = 'fund') {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.MEDIAS.FUNDS.DELETE + id + '/' + type).pipe(catchError(this.errorHandler));
  }

  public removePollsMedia(id: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.MEDIAS.POLL.DELETE + id).pipe(catchError(this.errorHandler));
  }

  public removeEventsMedia(id: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.MEDIAS.EVENTS.DELETE + id).pipe(catchError(this.errorHandler));
  }

  public removeMarketingMedia(id: number) {
    return this.baseService.delete(environment.baseUrl + APIENDPOINT.MEDIAS.MARKETING.DELETE + id).pipe(catchError(this.errorHandler));
  }

  //get group types
  public getGroupTypes(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.GROUPTYPE).pipe(catchError(this.errorHandler));
  }

  //get team types
  public getTeamTypes(){
    return this.baseService.get(environment.baseUrl + APIENDPOINT.SETTINGS.TEAMTYPE).pipe(catchError(this.errorHandler));
  }

  public sendInviteToChurch(data: any){
    return this.baseService.post(environment.baseUrl + APIENDPOINT.SETTINGS.INVITECHURCH.INVITE, data).pipe(catchError(this.errorHandler));
  }

  //Method to handle error
  errorHandler(error:any) {
    return of(error);
  }

     //Error toast
  async errorToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'danger',
        buttons: ['OK']
      });
    toast.present();
  }

  async successToast(message: any) {
    const toast = await this.toastController.create({
        message: message,
        color: 'success',
        buttons: ['OK']
      });
    toast.present();
  }
}
