import { Component, OnInit } from '@angular/core';
import { AppUpdateService } from 'src/app/services/app-update.service';
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-update-notifier',
  templateUrl: './update-notifier.component.html',
  styleUrls: ['./update-notifier.component.scss'],
})
export class UpdateNotifierComponent implements OnInit {

  public isUpdating: boolean = false;
  public isUpdateAvailable: boolean = false;
  public upToDate: boolean = false;
  public currentVersion: string;
  public newVersion: string;
  public appName: string = 'Avance';

  constructor(private updateservice: AppUpdateService, private modal: ModalController) {
    
  }

  async ngOnInit() {
    this.isUpdateAvailable = await this.updateservice.isUpdateAvailable();
    this.upToDate = !this.isUpdateAvailable;
    this.appName = (await App.getInfo()).name;
    this.newVersion = await this.updateservice.getAvailableAppVersion() as string;
    this.currentVersion = (await App.getInfo()).version;
  }

  closeModal() {
    if (this.isUpdating) {
      return;
    }
    this.modal.dismiss({upToDate: this.upToDate}, 'dismiss');
  }

  get isAndroid() {
    return Capacitor.getPlatform() === 'android';
  }

  async openAppStore(){
    await this.updateservice.openAppStore();
  }

  immediateUpdate() {
    this.isUpdating = true;
    this.upToDate = false;
    this.updateservice.performImmediateUpdate()
    .then(async () => {
      await this.ngOnInit();
    })
    .finally(() => this.isUpdating = false);
  }

}
