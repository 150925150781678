import { DOCUMENT, registerLocaleData } from '@angular/common';
import { Component, Inject, NgZone, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppUpdateService } from './services/app-update.service';
import { ModalController, Platform } from '@ionic/angular';
import { UpdateNotifierComponent } from './components/update-notifier/update-notifier.component';
import { normalizeUrl } from './helpers/functions';
import { environment } from 'src/environments/environment';
import { WebSocketService } from 'src/app/services/websocket.service';

import { TranslationService } from './services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { RTLLanguagesCodes } from './helpers/constant/supported-language';
import { InAppPurchaseService } from './services/in-app-purchase.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent{
  constructor(
    private router: Router, 
    private zone: NgZone, 
    @Inject(DOCUMENT) private document: Document, 
    private updateChecker: AppUpdateService, 
    private modal: ModalController, 
    private platform: Platform,
    private webSocketService: WebSocketService,
    public translationService: TranslationService,
    public translate: TranslateService,
    private renderer: Renderer2
  ) {
    this.translate.onLangChange.subscribe((value) => {
      this.document.querySelector("html")?.setAttribute("lang", value.lang.replace("-", "_"));
      if (RTLLanguagesCodes.includes(value.lang.toLowerCase())) {
        this.document.querySelector("html")?.setAttribute("dir", 'rtl');
      } else {
        this.document.querySelector("html")?.setAttribute("dir", 'ltr');
      }
    })
    this.initializeApp();
  }

  async initializeApp() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.applyThemeBasedOnZoneUrl(event.urlAfterRedirects);
      }
    });

    await this.platform.ready();
    this.initializeFirebase();
    this.keyboardListener();
    await SplashScreen.hide();

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.changeStatusBarColor(prefersDark.matches);
    prefersDark.addEventListener('change', (mediaQuery) => {
      this.changeStatusBarColor(mediaQuery.matches);
    });

    await this.checkForUpdate();

    this.webSocketService.registerWebSocketListeners();
  }

  public initializeFirebase() {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }

  async checkForUpdate() {
    if (Capacitor.isNativePlatform()) {
      let updateAvailability = await this.updateChecker.isUpdateAvailable();
      if (updateAvailability) {
        await this.openUpdateDialog();
      }
    }

    this.initDeepLink();
  }

  changeStatusBarColor(toDark: boolean = false) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: toDark ? Style.Dark : Style.Light});
    }
  }

  async openUpdateDialog() {
    const modal = await this.modal.create({
      component: UpdateNotifierComponent,
      animated: true,
      backdropDismiss: false,
      keyboardClose: false,
      showBackdrop: true
    });
    await modal.present();
    await modal.onDidDismiss();
  }

  initDeepLink() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        // test if the url is a regular url or our custom url scheme 
        let  slug = normalizeUrl(event.url);
        if (slug) {
          await this.router.navigateByUrl(slug);
        }
      })
    });
  }

  keyboardListener() {
    window.addEventListener('keyboardDidShow', (event: any) => {
      this.document.querySelector('html')?.classList.add('keyboard-open');
    })
    window.addEventListener('keyboardDidHide', () => {
      this.document.querySelector('html')?.classList.remove('keyboard-open');
    })
  }

  applyThemeBasedOnZoneUrl(url: string) {
    if (url.includes('zone/account-holder')) {
      this.renderer.addClass(document.body, 'secondary-theme');
      this.renderer.removeClass(document.body, 'tertiary-theme');
      this.renderer.removeClass(document.body, 'admin-theme');
    } else if (url.includes('zone/super-admin')) {
      this.renderer.addClass(document.body, 'admin-theme');
      this.renderer.removeClass(document.body, 'tertiary-theme');
      this.renderer.removeClass(document.body, 'secondary-theme');
    } else if (url.includes('zone/shepherd')) {
      this.renderer.addClass(document.body, 'tertiary-theme');
      this.renderer.removeClass(document.body, 'secondary-theme');
      this.renderer.removeClass(document.body, 'admin-theme');
    } else {
      // Default theme
      this.renderer.removeClass(document.body, 'tertiary-theme');
      this.renderer.removeClass(document.body, 'secondary-theme');
      this.renderer.removeClass(document.body, 'admin-theme');
    }
  }

}
