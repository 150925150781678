export const APIENDPOINT = {
  AUTH: {
      LOGIN: { VALIDATEUSER: 'auth/login' },
      LOGOUT: 'auth/logout',
      ROLES: 'auth/roles/', // auth/roles/{user}/{church}
      CHANGEEMAIL: 'auth/change-email' 
  },
  LANGUAGE: {
    LOAD: 'language/load/', // {language}
    LOADALL: 'language/get',
    GETLIST: 'language/list', // {language?}
    NEW: 'language/create', // POST {name, code}
    SAVE: 'language/save/', // {language} POST {translations}
    PUBLISH: 'language/publish/', // {language} PUT
    UNPUBLISH: 'language/unpublish/', // {language} DELETE
  },
  SETTINGS:{
    COUNTRIES: {
      COUNTRIESLIST: 'settings/countries/',
      COUNTRIESSEARCH: 'settings/countries-search/',
      GETCOUNTRY: 'settings/country/', // {country}
    },
    CITIES: { 
      CITIESLIST: 'settings/cities',
      CITIESSEARCH: 'settings/cities-search/'
    },
    CHURCHES:{
      CHURCHLIST: 'churches/city/', // 'churches/city/{offset?}'
      INVITEMEMBERS: 'churches/invite-members/', // {church}
      GET: 'churches/get/', // {church}
    },
    CHECK_REFERRALCODE: 'settings/referral-code-exists/', // GET {referral}
    RATES: 'settings/rates', // GET
    EXPERIENCES: 'settings/experiences',
    CONGREGATION: { CONGREGATIONLIST: 'congregations/list' },
    RELATIONSHIP: { RELATIONSHIPLIST: 'settings/relationships' },
    GENDER: { GENDERLIST: 'settings/gender' },
    FAMILYMEMBERS: {
      FAMILYMEMBERLIST: 'settings/family-member-search/', // {church}/{offset?}/{church2?}
    },
    GETROLES: { ROLES: 'settings/roles' },
    FORGOTPASSWORD: 'settings/password-reset-email',
    RESETPASSWORD: 'settings/reset-password',
    CURRENCIES: { CURRENCIESLIST: 'settings/paypal-currencies' },
    FREQUENCY: { FREQUENCYLIST: 'settings/frequency' },
    GROUPTYPE: 'settings/groups-types',
    TEAMTYPE: 'settings/teams-types',
    CALCULATEFEE: 'settings/calculate-fees/', // {amount}
    CALCULATEUSERFEE: 'settings/user-payout-fee/', // {amount}
    CALCULATECHURCHFEE: 'settings/payout-fee/', // {amount}
    INVITECHURCH: {
      INVITE: 'settings/invite-church',
      GETLIST: 'settings/invited-churches/', //{offset}
    },
    GETTRANSACTIONFEEBYNAME: 'settings/get-transaction-fee/', // {name = transaction-fee-name}
    GETCOUNTRYBYTIMEZONE: 'settings/get-country-by-timezone' // {name = transaction-fee-name}
  },
  SHEEP:{
    REGISTRATION: {SHEEPDATA: 'registration/sheep' },
    PAYPAL: 'sheep/link-paypal',
    CHANGELANGUAGE: 'sheep/change-language', // POST {language}
    NEWS: {
      SUBMITNEWS: 'news/create-as-sheep',
      GETNEWSITEM: 'sheep/news-details/', // {id}/{type?}
      LISTNEWNEWSPOLLS: 'sheep/new-news-polls/', // sheep/new-news-polls/{sheep}/{church}/{offset?}
      LISTSEENNEWSPOLLS: 'sheep/seen-news-polls/', // sheep/seen-news-polls/{sheep}/{church}/{offset?}
      MOVENEWSPOLLSTOSEEN: 'sheep/news-poll-interaction',
      ADDNEWSCOMMENT: 'news/add-comment',
      ADDPOLLCOMMENT: 'polls/add-comment',
      ADDNEWSREACTION: 'news/reaction',
      ADDPOLLREACTION: 'polls/reaction',
      ADDPOLLVOTE: 'polls/vote',
      CITYNEWS: 'sheep/city-news/', // {offset?}
      LISTNEWSCOMMENTS: 'news/comments/',
      LISTPOLLCOMMENTS: 'polls/comments/',
    },
    GIVING: {
      GET: 'funds/get/', // {type}/{fund}'
      SHAREQRCODE: 'funds/share-qrcode/', // {fund}
      GUESTDONATION: {
        MAKEPAYMENT: 'payments/guest-donation', // POST {"fund", "type", "amount", "user"}
        CAPTUREORDER: 'payments/process-guest-donation', // {"order_id", "captured_order_id", "user"}
      },
      LISTFUNDSANDFUNDRAISERS: 'sheep/funds-fundraisers/', // sheep/funds-fundraisers/{church}/{offset?}
      DONATE: 'payments/give', // POST { "church_id", "id", "type": "fund"|"fundraiser", "amount": min(3), "anonymous": boolean, "recurring": false, "recurring_option": "weekly" (required with recurring true) }
      // DONATE: 'payments/donate', // old implementation
      CHECKORDER: 'payments/check-order',
      RECURRINGPAYMENTS: 'recurring-payments/list/', // {church}/{offset?}/{status}/
      CANCELRECURRING: 'recurring-payments/cancel', // POST {"church_id", "type", "id"}
      TRANSACTIONHISTORY: 'sheep/transaction-history/', // sheep/transaction-history/{church}/{offset?}
    },
    CONNECT: {
      GROUP: {
        CREATE: 'groups/create',
        INVITE: 'groups/send-invite',
        JOIN: 'groups/join',
        UPDATE: 'groups/update/', //groups/update/{group}
        DELETE: 'groups/', //groups/{group}/{church}
        LEAVE: 'groups/leave/', // groups/leave/{group}/{church}
        LIST: 'groups/list/', //groups/list/{church}/{offset}/{sheep?}
        SEARCH: 'groups/search/', //groups/search/{offset}
        USERGROUPS: 'groups/user-groups/', //groups/user-groups/{church}/{offset}
        GROUPDETAILS: 'groups/details/', //groups/details/{church}/{group}
        LEADERS: 'groups/leaders/', //{church}/{offset?}/{group?}
        HANDOVER: 'groups/handover',
        PARTICIPANTS: {
          LIST: 'groups/participants/', //{group}/{church}/{offset}
          REMOVE: 'groups/remove-participant/', //{group}/{church}/{participant}
          ADD: 'groups/add-participants/', //{group}
        },
        EVENTS: {
          UPCOMING_COUNT: 'groups/upcoming-events/count/', // {group}
          UPCOMING: 'groups/upcoming-events/', // {group}/{offset?}
          DETAILS: 'groups/event/', // {group}/{event}
        },
        ATTENDANCE: {
          MARK: 'groups/mark-attendance',
          DATA: 'groups/attendance-data',
          USER: 'groups/user-attendance/', //{church}
        },
        MESSAGES: {
          SEND: 'groups/send-message',
          GET: 'groups/messages/', //{church}/{group}/{offset}
          READ: (messageId: number) => `groups/message-receipt/${messageId}/read`, 
          RECEIPT: (messageId: number) => `groups/message-receipt/${messageId}/delivered`, 
        },
        STATS: 'groups/stats/', //{church}

      },
      TEAM: {
        CREATE: 'teams/create',
        INVITE: 'teams/send-invite',
        JOIN: 'teams/join',
        UPDATE: 'teams/update/', //teams/update/{team}
        DELETE: 'teams/', //teams/{team}/{church}
        LEAVE: 'teams/leave/', // teams/leave/{group}/{church}
        LIST: 'teams/list/', //teams/list/{church}/{offset}
        SEARCH: 'teams/search/', //teams/search/{offset}
        USERTEAMS: 'teams/user-teams/', //teams/user-teams/{church}/{offset}
        TEAMDETAILS: 'teams/details/', //teams/details/{church}/{teams}
        LEADERS: 'teams/leaders/', //{church}/{offset}
        HANDOVER: 'teams/handover',
        PARTICIPANTS: {
          LIST: 'teams/participants/', //{team}/{church}/{offset}
          REMOVE: 'teams/remove-participant/', //{team}/{church}/{participant}
          ADD: 'teams/add-participants/', //{team}
        },
        EVENTS: {
          UPCOMING_COUNT: 'teams/upcoming-events/count/', // {team}
          UPCOMING: 'teams/upcoming-events/', // {team}/{offset?}
          DETAILS: 'teams/event/', // {team}/{event}
        },
        ATTENDANCE: {
          MARK: 'teams/mark-attendance',
          DATA: 'teams/attendance-data',
          USER: 'teams/user-attendance/', //{church}
        },
        MESSAGES: {
          SEND: 'teams/send-message',
          GET: 'teams/messages/', //{church}/{team}/{offset}
          READ: (messageId: number) => `teams/message-receipt/${messageId}/read`, 
          RECEIPT: (messageId: number) => `teams/message-receipt/${messageId}/delivered`, 
        },
        STATS: 'teams/stats/', //{church}
      }
    },
    EVENT: {
      GET: {
        UPCOMING: 'sheep/upcoming-events/', //{church}/{offset}
        REGISTERED: 'sheep/registered-events/', //{church}/{offset}
        EXPORT: 'sheep/export-registered-events/', //{church}
        CITY: 'sheep/city-events/', // {church}/{offset?}'
        DETAIL: 'sheep/event/detail/', // {church}/{event}
      },
      INVITE: 'events/invite', // POST {"church_id", "event_id", "invites": [1,2]}
      REGISTER: 'events/register',
      PURCHASED_TICKETS: 'sheep/event-tickets/', // {church}/{event}/{offset?}'
      BUYTICKETS: 'events/purchase-ticket', // POST { church_id, event_id, tickets: { ticket_id, quantity }}
      SHARETICKET: 'events/email-qrcode', // POST {"ticket_serial_no", "email", "qr_code"}
      UNREGISTER: 'events/unregister', // POST {"event_id"}
      VERIFYPAYMENT: 'events/verify-payment',
      LIKE: 'events/like',
      COMMENT: {
        ADD: 'events/add-comment', // POST {"church_id", "event_id", 'comment'}
        LIST: 'events/comments/', // {event}/{offset}
        REMOVE: 'events/delete-comment/', // {church}/{event}/{comment}
      }
    },
    ROSTERS: {
      LIST: 'rosters/sheep-list/', // {church}/{offset}
      DETAILS: 'rosters/sheep-details/', // {church}/{roster}
      PARTICIPANTS: 'rosters/participants-list/', //{roster}/{schedule}'
      CHANGEAVAILABILITY: 'rosters/change-availability', // POST {'church_id', 'roster_id', 'schedule_id', 'date'}
      NOTAVAILABILITY: 'rosters/not-available', // POST {'church_id', {'roster_id', 'schedule_id'}[], 'date'},
      DELETENOTAVAILABILITY: 'rosters/not-available/delete/', // {roster_unavailability}
      SWAP: 'rosters/swap', // POST {"church_id", "roster_id", "schedule_id", "replacement_schedule_id"}
      REPLACE: 'rosters/replace', // POST {"church_id", "roster_id", "schedule_id", "replacement_schedule_id"}
      ONDUTY: 'rosters/on-duty/', // {church}/{date}/{offset?} get rosters in the giving date
      FASTFORWARD: 'rosters/fast-forward/', // {church}/{date} get rosters in the giving date
      UNAVAILABLE_DATES: 'rosters/unavailability-dates/', // {chruch}/{user?}?date=?&offset=?
      REQUEST: 'rosters/request/', //{swapReplaceId}
      SWAPACCEPTREJECT: 'rosters/swaprequest/', //{swapRequest}/{seeker}/{actionType}/{isFrontend} 
    },
    LINKEDCHURCHES: 'sheep/linked-churches/',
    PROFILE: {
      SHOW: 'sheep/profile/',
      EDIT: 'sheep/edit_profile/',
      EDITRELATIONALSTATUS: 'sheep/edit_profile/{sheep}/relational_status',
      EDITEMPLOYMENTSTATUS: 'sheep/edit_profile/{sheep}/employment_status',
    },
    KIDS: {
      ADD_EDIT: 'sheep/add-edit-kid/', // sheep/add-edit-kid/{user}
    },
    FAMILYMEMBERS: {
      LIST_REQUESTS: 'sheep/list-family-request/', // list-family-request/{user}/{offset?}
      LINK_DELINK: 'sheep/link-delink-family/', // sheep/link-delink-family/{user}/{familyMember}
      ACCEPT_DECLINE: 'sheep/accept-decline-family/', // sheep/accept-decline-family/{user}/{familyMember}
    },
    CHURCH: {
      LINK: 'sheep/link-church/', // sheep/link-church/{sheep}/{church}
      DELINK: 'sheep/delink', // sheep/delink
    },
    COURSE: {
      CURRENT: 'courses/current/', // {church}/{course}/{student}/{coach?}
      LIST: {
        INSTORE: 'courses/in-sheep-store/', // {church}/{offset}
        MYSTUDENTS: 'courses/coaching/', // {church}/{offset}
        MYJOURNEY: 'courses/my-journey/', // {church}/{offset}
      },
      REQUEST: {
        LIST: 'courses/requests/', // {church}/{offset}
        SEND: 'courses/send-request', // POST {"church_id", "coach_id", "course_id"}
        APPROVE: 'courses/approve-request', // POST {"church_id", "coach_request_id"}
        REJECT: 'courses/reject-request', // POST {"church_id", "coach_request_id"}
      },
      ENROLLMENT: 'courses/enrollment', // POST {"church_id", "course_id"}
      LINKSLIST: 'courses/family-links-list/', // {course}/{church}
      PAYFORLINKS: 'courses/family-link-payment', // POST {"church_id", "course_id", "links": number[]}
      SAVE_PROGRESS: 'courses/save-progress', // POST{"church_id", "session_id", "course_id", "type": "normal", "quizzes": { "quiz_id", "option_id"}[] }
      RESTART: 'courses/restart', // POST{"church_id", "course_id" }
      SESSION: {
        STUDENT: 'courses/session/', // {church}/{course}/{session?}
        COACH: 'courses/session-for-coach/' // {church}/{course}/{session}/{student}
      },
      TESTIMONY: {
        ADD: 'courses/add-testimony', // POST {"church_id", "testimony", "course_id"}
      },
      MARKAS_COMPLETED: 'courses/coach-session', // POST {"church_id", "course_id", "session_id", "user_id"}
      MESSAGE: {
        SEND: 'courses/send-message', // POST {"church_id", "course_id", "receiver_id", "is_coach", "message", "voice_note", "media":{type, data}[]}
        LIST: 'courses/messages/', // {church}/{course}/{student}/{coach}/{offset}
        RECEIPTMESSAGE: (userType: 'coach'|'student', messageId: number) => `courses/message-receipt/${userType}/${messageId}/delivered`,
        READMESSAGE: (userType: 'coach'|'student', messageId: number) => `courses/message-receipt/${userType}/${messageId}/read`,
      },
      RESULTS: 'courses/result/' // {course}/{church}/{offset}
    },
    DEACTIVATEACCOUNT: 'sheep/deactivate-account', //  POST {"user"},
    SERMONS: {
      LIST: 'sermon/sheep-list/', // {offset} POST
      SERIES: 'sermon/series/', // {church}/{offset}
      DETAIL: 'sermon/data/', // {sermon}/{church}
      PROGRESS: 'sermon/update-progress', // POST {church_id, progress, sermon_id}
      SHAREQRCODE: "sermon/share-qrcode/", // {sermon}
      MARKDISCUSSION: 'sermon/mark-discussion', // POST {church_id, sermon_id, discussion_id}
      ADDNOTE: 'sermon/add-note', // POST {church_id, sermon_id, note}
      REMOVENOTE: 'sermon/remove-note/' // {church_id}/{sermon_id}/{note}
    },
    PRAYER_REQUEST: {
      ADDCOMMENT: 'prayer-request/add-comment', // POST {church_id, prayer_request_id, comment}
      REACT: 'prayer-request/add-reaction', // POST {church_id, prayer_request_id, reaction}
      DELETECOMMENT: 'prayer-request/delete-comment/', // {church}/{prayer}/{comment} DELETE,
      REMOVECOMMENT: 'prayer-request/remove-comment/', // {church}/{prayer}/{comment} DELETE, as shepherd
      COMMENTS_REACTIONS: (prayer: number, offset: number = 0) => `prayer-request/${prayer}/comments/${offset}`
    },
    CHANGECONGREGATION: 'sheep/change-congregation/', // {church}/{congregation}'
    CHANGEPASSWORD: 'sheep/change-password'
  },
  ACCOUNTHOLDER:{
    REGISTRATION: { ACCOUNTHOLDERDATA: 'registration/account-holder' },
    GETCHURCHBYID: { CHURCHDETAILS: 'account-holder/church/' },
    UPDATECHURCH: { UPDATE: 'churches/update/' },
    GETSHEPHERDS: 'account-holder/shepherds/',
    LINKPAYPAL: 'churches/link-paypal',
    ACCOUNTHOLDERCONGREGATION: { CONGREGATIONLIST: 'account-holder/congregation/' },
    CONGREGATION: {
      GET: 'account-holder/congregation-by-id/', // congregation-by-id/{church}/{congregation}
      LIST: 'congregations/list/', // congregations/list/{church}/{offset?}
      ADD: 'congregations/save',
      UPDATE: 'congregations/update/',
      DELETE: 'congregations/'
    },
    FUNDS: {
      ADDFUND: 'funds/create',
      FUNDLIST: 'account-holder/fund/', // account-holder/fund/{user}/{church}/{offset?}
      UPDATEFUND: 'funds/update/',
      DELETEFUND: 'funds/'
    },
    FUNDRAISER: {
      ADDFUNDRAISER: 'funds/raiser-create',
      FUNDRAISERLIST: 'account-holder/fund-raiser/', // account-holder/fund-raiser/{user}/{church}/{offset?}
      UPDATEFUNDRAISER: 'funds/raiser-update/',
      DELETEFUNDRAISER: 'funds/raiser/'
    },
    FUNDTRACKER:{
      FUNDLIST: 'statement/tracker/',
      STATEMENTS: 'statement/recent-transactions/'
    },
    STATEMENT: {
      LIST: 'statement/list/',
      EXPORT: 'statement/export',
      FUNDEXPORT: 'statement/recent-transactions-export'
    }
  },
  SHEPHERD:{
    PERMISSIONS: 'shepherd/user-permissions/', // 'shepherd/user-permissions/{sheep}/{church}'
    SHEEPMANAGEMENT: {
      QUICKSEARCH: 'sheep-management/quick-search/', // 'sheep-management/quick-search/{offset?}'
      DETAILEDSEARCH: 'sheep-management/detailed-search/', //'sheep-management/detailed-search/{offset?}'
      EXPORTUSERS: 'sheep-management/export-users',
      NEWLINKS: 'sheep-management/new-links/', // 'sheep-management/new-links/{church}/{offset?}'
      DELINK: 'sheep-management/delink',
      DELINKEDLIST: 'sheep-management/delinked-list/', // 'sheep-management/delinked-list/{offset?}'
      DELINKEDSTATS: 'sheep-management/delinked-stats/', // 'sheep-management/delinked-stats/{church}'
      SHEEPDETAILS: 'sheep-management/sheep-details/', // 'sheep-management/sheep-details/{sheep}/{church}/{offset?}'
      ADDTOGROUP: 'groups/add-to-group/', // {group}/{user}
      ADDTOTEAM: 'teams/add-to-team/', // {team}/{user}
      ACTIVITY: {
        NEWS: 'sheep-management/activity-news/',
        EVENTS: 'sheep-management/activity-events/',
        COURSES: 'sheep-management/activity-courses/',
        GROUPTEAM: 'sheep-management/activity-groups-teams/',
        JOURNEY: 'sheep-management/journey/', // {sheep}/{church}/{offset?}
        ASSIGNEDSHEEP: 'sheep-management/assigned-sheep', // POST
        GROUPLEADERS: 'sheep-management/group-leaders', // POST
        TEAMLEADERS: 'sheep-management/team-leaders', // POST
      },
      UPDATECONGREGATION: 'sheep-management/update-congregation/',
      UPDATESHEPHERD: 'sheep-management/update-shepherd/',
      COMMUNICATION: 'sheep-management/communication',
      GETALLCHURCHUSERS: 'sheep-management/church-users/', // 'sheep-management/church-users/{church}/{offset?}'
      KIDS: {
        CLASSLIST: 'kids/class-list/', // kids/class-list/{church}/{offset}
        KIDSLIST: 'kids/kids-list/', // GET {church}/{class}/{offset} POST {name}
        CLASSDETAILS: 'kids/class-details/', // kids/class-details/{class}/{offset}
        CHARTDATA: 'kids/chart-details/', // kids/chart-details/{class}
        FILTERDATA: 'kids/filter-chart/', // kids/filter-chart/{class}
        CREATECLASS: 'kids/create-class',
        ADDVISITOR: 'kids/add-visitor',
        EDITVISITOR: 'kids/edit-visitor',
        DELETECLASS: 'kids/',
        ASSIGNKIDCLASS: 'kids/assign-class/', // Use GET to assign and DELETE to DISSOCIATE {"user_id", "kid_id", "kid_class_id", "church_id"}
        DISSOCIATEKID: 'kids/dissociate-class/', //{church}/{kid}/{class}
        OPENCLASS: 'kids/open-class',
        CLOSECLASS: 'kids/close-class',
        CHECKIN: 'kids/checkIn',
        CHECKOUT: 'kids/checkOut',
        SEND_CHECKOUT_NOTIFICATION: 'kids/send-checkout-notification',
      },
      NOTES: {
        LIST: 'sheep-management/notes/', // sheep-management/notes/{sheep}/{church}/{offset?}
        ADD: 'sheep-management/add-notes/', //sheep-management/add-notes/{sheep}
        DELETE: 'sheep-management/delete-notes/', // sheep-management/delete-notes/{note}/{shepherd}
      },
      TAGS: {
        ADD: 'sheep-management/add-tag/', // {church} POST {"name","color","rule","model","model_value"}
        ASSIGN: 'sheep-management/assign-tag/', // {church}/{sheep}/{churchTag}
        ASSIGNTOUSERS: 'sheep-management/assign-tag-users/', // {church}/{tag} PUT {sheeps = {user_id: number, action: string}[]}
        EDIT: 'sheep-management/edit-tag/', // {church}/{churchTag} POST {"name","color","rule","model","model_value"}
        DELETE: 'sheep-management/remove-tag/', // {church}/{churchTag}
        LIST: 'sheep-management/tags/', // {church}
        LISTUSERS: 'sheep-management/users-by-tag/', // {church}/{tag}/{offset?}/{name?}
        REVOKE: 'sheep-management/revoke-tag/', // {church}/{sheep}/{churchTag}
      }
    },
    NEWS: {
      CREATENEWS: 'news/create-as-shepherd',
      CREATEPOLL: 'polls/create',
      PENDINGNEWS: 'news/pending/', // news/pending/{shepherd}/{offset?}
      APPROVENEWS: 'news/approve',
      DISCARDNEWS: 'news/discard',
      LISTNEWS: 'news/active/', // news/active/{shepherd}/{offset?}
      LISTPOLL: 'polls/list/', // polls/list/{shepherd}/{offset?}
      DELETENEWS: 'news/',
      DELETEPOLL: 'polls/',
      GETNEWSBYID: 'news/get/',
      GETPOLLBYID: 'polls/get/',
      DELETENEWSCOMMENT: 'news/delete-comment/',
      DELETEPOLLCOMMENT: 'polls/delete-comment/',
      UPDATENEWS: 'news/update/',
      UPDATEPOLL: 'polls/update/',
    },
    STATISTICS: {
      GETACTIVESHEEP: 'statistics/active-sheep/', // {church}
      GETTEAMLEADERS: 'statistics/team-leaders/', //{church}
      GETGROUPLEADERS: 'statistics/group-leaders/', //{church}
      GETACTIVESHEPHERD: 'statistics/active-shepherd/', // {shepherd}/{church}
      GETEVENTREPORT: 'statistics/event-report/', // {church}/{event?}
      GETEVENTREPORTSTATS: 'statistics/event-report-stats/', // {church}/{type?}/{congregation?}
      GETACTIVECOURSE: 'statistics/active-courses/', // /{church}/{offset?}/{congregation?}
    },
    EVENTS: {
      CREATE: 'events/save',
      UPDATE: 'events/update/', //{church}/{event}
      DELETE: 'events/', //{event}
      GET: {
        ALL: 'events/all/', // {church}/{offset?}
        UPCOMING: 'events/upcoming/', //{church}/{offset}
        PAST: 'events/past/', //{church}/{offset}
        DETAILS: 'events/details/', //{church}/{event}
        TYPES: 'settings/events-types'
      },
      DEREGISTER: 'events/deregister',
      ATTENDEES: {
        GET: 'events/attendees/', //{church}/{event}/{offset}
        EXPORT: 'events/export-attendees'
      },
      REPORT: {
        UPDATE: 'events/update-report',
      },
      ROSTERS: {
        SAVE: 'rosters/save',
        LIST: 'rosters/list/', //{church}/{offset}
        SEARCH: 'rosters/list/', //{church}/{offset}/ {'name'}
        DETAILS: 'rosters/details/', //{church}/{roster}
        PARTICIPANTS: 'rosters/participants',
        CHANGEAVAILABILITY: 'rosters/change-availability',
        UPDATE: 'rosters/update',
        DELETE: 'rosters/', //{roster}
      },
      TICEKTS: {
        CHECKVALIDITY: 'events/ticket-checker/', // {church}/{ticket_serial_no}
        MARKAS_USED: 'events/mark-ticket', // POST {"church_id", "ticket_serial_no"}
      }
    },
    COURSE: {
      CREATE: 'courses/save', // POST
      INSTALL: 'courses/install', // POST {"church_id", "course_id"}
      UNINSTALL: 'courses/uninstall', // POST {"church_id", "course_id"}
      UPDATE: 'courses/update/', // {course} PUT {"church_id", "title", "description", "video_url", "photo", "installable" }
      UPDATESESSION: 'courses/update-session/', // {course}/{session} PUT session data
      GETDETAIL: 'courses/info/', // {church}/{course}
      LIST: {
        INSTORE: 'courses/in-store/', // {church}/{offset}
        MANAGE: 'courses/manage/', // {church}/{offset}
        LIVEUPDATE: 'courses/live-updates/', // {church}/{course}
      },
      TESTIMONY: {
        REMOVE: 'courses/remove-testimony/', // DELETE {church}/{course}/{testimony},
        LIST: 'courses/testimonies/' // {course}/{offset}
      },
      REMOVE: 'courses/', // DELETE {church}/{course}
    },
    SERMONS: {
      SAVE: 'sermon/save', // POST
      LIST: 'sermon/list/', // {church}/{offset}
      UPDATE: 'sermon/update/', // {sermon} PUT
      PUBLISH: 'sermon/publish/', // {sermon} PUT
      ARCHIVE: 'sermon/', // {church}/{sermon} DELETE,
    },
    PRAYER_REQUEST: {
      LIST: 'prayer-request/list/', // {church}/{offset}
      DETAIL: 'prayer-request/details/', // {church}/{prayer}
      REMOVECOMMENT: 'prayer-request/remove-comment/', // {church}/{prayer}/{comment} DELETE
      CREATE: 'prayer-request/save', // POST {church_id, title, media, items}
      UPDATE: 'prayer-request/update/', // {prayer} PUT {church_id, title, media, items}
      DELETE: 'prayer-request/', // DELETE {church}/{prayer}
    }
  },
  MARKETING: {
    CREATE: 'marketing-news', // POST
    UPDATE: 'marketing-news/', // {news} PUT 
    DELETE: 'marketing-news/', // {news} DELETE
    GET: 'marketing-news/get/', // GET {news}
    LIST: 'marketing-news/', // GET {offset}
  },
  SUPERADMIN:{
    DASHBOARD:{
      DATA: 'super-admin/dashboard',
      ENGAGINGGROUPS: 'super-admin/engaging-groups/', //{offset}
      ENGAGINGTEAMS: 'super-admin/engaging-teams/', //{offset}
    },
    CHURCH: {
      LISTCHURCH: 'super-admin/churches/', // 'super-admin/churches/{offset?}'
      GETCHURCHBYID: 'super-admin/church-info/', // {church}/{offset?}
      UPDATECHURCH: 'super-admin/update-church/',
      DELETECHURCH: 'super-admin/delete-church/',
    },
    USER: {
      LISTUSER: 'super-admin/users-list/', // 'super-admin/users-list/{offset?}'
      GETUSERBYID: 'super-admin/user-info/',
      UPDATEUSER: 'super-admin/update-user/',
      DELETEUSER: 'super-admin/delete-user/',
    },
    FINANCES: {
      TRANSACTIONS: 'super-admin/transactions/', // super-admin/transactions/{offset?}
      CHURCHTRANSACTIONS: 'super-admin/church-transactions/', // super-admin/church-transactions/{church}/{offset?}
      EXPORTTRANSACTIONS: 'super-admin/export-transactions',
      EXPORTPAYOUTS: 'super-admin/export-payouts'
    },
    GROUP: {
      LIST: 'super-admin/groups/', //{offset}
      INFO: 'super-admin/group-info/', //{group}/{offset}
      UPDATE: 'super-admin/update-group/', //{group}
      DELETE: 'super-admin/delete-group/', //{group}
    },
    TEAM: {
      LIST: 'super-admin/teams/', //{offset}
      INFO: 'super-admin/team-info/', //{tema}/{offset}
      UPDATE: 'super-admin/update-team/', //{team}
      DELETE: 'super-admin/delete-team/', //{team}
    },
    EVENT: {
      UPCOMING_EVENTS: 'super-admin/upcoming-events/', // {offset?}/{church?}
      DELETE: 'super-admin/event-delete/', // {event}
      DETAILS: 'super-admin/event-details/', // {event}
      UPDATE: 'super-admin/event-update/', // {event}
    },
    COURSES: {
      LIST: 'super-admin/courses/', // {offset?}
      GET: 'super-admin/course-info/', // {course}
      UPDATE: 'super-admin/update-course/', // {course} PUT
      POPULARS: 'super-admin/popular-courses/', // {offset?}
      ARCHIVE: 'super-admin/delete-course/', // {course}
    },
    SALESAGENT: {
      GET: 'super-admin/sales-agent/', // {agent}
      LIST: 'super-admin/sales-agents/', // {offset}
      SAVE: 'super-admin/save-sales-agent', // POST for creating / PUT /{agent} for update
      ACTIVATE: 'super-admin/activate-sales-agent/', // {agent}
      ARCHIVE: 'super-admin/archive-sales-agent/', // DELETE {agent}
      AFFILIATEDCHURCHES: 'super-admin/affiliated-churches/', // {agent}/{offset}
      PAYMENTS: 'super-admin/sales-agent-payments/', // {agent}/{offset}
      INVITECHURCH: 'super-admin/invite-church', // POST
    },
    TRANSACTIONFEES:{
      LIST: 'super-admin/transaction-fees-list',
      EDIT: 'super-admin/update-transaction-fee' //{fee}
    }
  },
  SHEEPMANAGEMENT:{
    EMPOWERMENT:{
      SHEPHERD: 'empowerment/shepherd',
      ADMINISTRATOR: 'empowerment/administrator',
      GROUPLEADER: 'empowerment/group-leader',
      TEAMLEADER: 'empowerment/team-leader',
      REMOVE: 'empowerment/remove'
    }
  },
  UTILS: { // Routes that are not specifics to a zone can be here
    CHURCH: {
      FIND: 'churches/find/' // churches/find/{offset?}
    },
    EMAILVERIFICATION: {
      CHECK: 'registration/check-email',
      RESEND: 'registration/resend-verification-email',
    }
  },
  MEDIAS: {
    NEWS: {
      DELETE: 'medias/news/delete/', // medias/news/delete/{mediaId}
    },
    POLL: {
      DELETE: 'medias/poll/delete/', // medias/news/delete/{mediaId}
    },
    EVENTS: {
      DELETE: 'medias/events/delete/', // medias/events/delete/{mediaId}
    },
    FUNDS: {
      DELETE: 'medias/funds/delete/', // medias/funds/delete/{mediaID}
    },
    MARKETING: {
      DELETE: 'medias/marketing/delete/', // medias/marketing/delete/{mediaID}
    }
  },
  APP_NOTIFICATION: {
    GET: 'app-notification/get/', // app-notification/get/{id}
    LIST: 'app-notification/list/', // app-notification/list/{offset?}/{filter?}
    COUNT: 'app-notification/count',
    READ: 'app-notification/read/', // app-notification/read/{id}
    READALL: 'app-notification/read-all',
    READSELECTED: 'app-notification/read-selected', // app-notification/remove
    REMOVE: 'app-notification/remove', // app-notification/remove
  },
  PUSH_NOTIFICATION: {
    REGISTER_TOKEN: 'push-notification/token/'
  },
  SUPPORT: {
    LIST: 'support/list/', // support/list/{offset}/{user?}
    CREATE: 'support/create/', // support/create/{user}
    DETAILS: 'support/details/', // support/details/{support}/{user?}
    LOAD_MESSAGES: 'support/messages/', // support/messages/{support}/{offset?}
    SEND_MESSAGE: 'support/message/', // support/message/{user}/{support}
    RECEIPTMESSAGE: (messageId: number) => `support/message-receipt/${messageId}/delivered`,
    READMESSAGE: (messageId: number) => `support/message-receipt/${messageId}/read`,
    MARK_AS: 'support/mark_as/', // support/mark_as/{support}
  },
  WALLET: {
    RECENT_ACTIONS: {
      USER_WALLET: 'payments/recent-actions/', // {offset}
      CHURCH_WALLET: 'payments/church-recent-actions/', // {church}/{offset}
    },
    WITHDRAW: 'payments/withdraw', // POST { "church_id", "type", "amount" }
    TOPUP: 'payments/topup', // POST { "church_id", "type", "amount" }
    VERIFY: 'payments/process', // POST { "order_id", "type", "captured_order_id" }
    INFO: 'payments/wallet-info/', // {walletKey}
    TRANSFER: 'payments/transfer', // POST { "from", "to", "amount" }
    SUPERADMIN: {
      CURRENT_BALANCES: 'super-admin/wallets',
      USERSLOGS: 'super-admin/users-wallet-logs/', // {offset?}
      CHURCHESLOGS: 'super-admin/churches-wallet-logs/', // {offset?}
      SUPASHEEPLOGS: 'super-admin/supa-wallet-logs/', // {offset?}
      CHURCHACTIONS: 'super-admin/church-transactions/', // {church}/{offset?}
      USERACTIONS: 'super-admin/user-transactions/', // {user}/{offset?}
      SEARCH: 'super-admin/search-wallet', // POST {"type", "name"}
    },
    USERWALLET_INFO: 'payments/user-wallet'
  },
  SUBSCRIPTION: {
    COUNTRYPLANS: {
      SAVE: 'subscriptions/customize-for-country/', // {subscription} POST {"country_id", "price"},
      ARCHIVE: 'subscriptions/archive-custom-subscription/', // {subscription_country}
      ACTIVATE: 'subscriptions/activate-custom-subscription/', // {subscription_country}
    },
    CREATE: 'subscriptions/create', // POST {"name", "price", "min_sheep", "max_sheep", "grace_days"}
    UPDATE: 'subscriptions/update/', // {subscription} PUT {"name", "price", "min_sheep", "max_sheep", "grace_days"}
    LIST: 'subscriptions/list/', // {offset}
    BYCOUNTRY: 'subscriptions/by-country/', // {country_id}
    CURRENT: 'subscriptions/current/', // {church}
    BILLS: 'subscriptions/bills/', // {church}/{offset}
    PAYBILL: 'subscriptions/pay', // POST {"church_id", "bill_id"}
    BILLEXPORT: 'subscriptions/bills-export', // POST {"church_id", "email"}
    FILTERCHURCHBILL: 'subscriptions/filter-bills/', // {church}/{from}/{to}/{offset?}
    INAPPPURCHASE: {
      VALIDATE: 'plan-purchase/validate/', // {product} POST {receipt, platform}
      LINKPURCHASE: 'plan-purchase/subscribed/', // {church} PUT {transaction_id, product_id}
      CHECKSTATUS: 'plan-purchase/check-subscription-status'
    }
  },
  TUTORIALS: {
    CATEGORIES: {
      GET: 'tutorial-guide/categories/', // {offset}/{title}
      ADD: 'tutorial-guide/categories/create',
      UPDATE: 'tutorial-guide/categories/update/', // {category}',
      DELETE: 'tutorial-guide/categories/delete/', // {category}',
      SWITCHSTATUS: 'tutorial-guide/categories/switch-status/', // {category}' // Active/Archive
    },
    GET: 'tutorial-guide/tutorials/', // {category}
    CREATE: 'tutorial-guide/create/', // {category}',
    UPDATE: 'tutorial-guide/update/', // {category}/{media}',
    DELETE: 'tutorial-guide/delete/', // {category}/{media}',
  }
}
